import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import MinistryItem from "../ministries/ministry-item"

const CarePage = (props) => {

  const { post, bannerHeight="408px", ministries, promoted } = props
  const { t } = useContext(LanguageContext)


  return(
    <>
    <Banner
      post={post}
      height={bannerHeight}
    />
    <section className="page-care">
    <div className="container p-0 p-t-80 p-b-80 page-care medium-p-t-56 small-p-t-24 small-p-b-40">
    <div className="row">
        <div className="col-sm-12 center-section ">
          <div className="row">
            <div className="col-sm-12">
            <h2 className="H2-Medium-C---bordeaux m-b-56 medium-m-b-40 small-m-b-16">
              {t("Els nostres ministeris d'atenció personal i social")}
            </h2>
            <div className="row p-l-r-153 small-p-l-r-0 ">
            {ministries && ministries?.edges.length && ministries?.edges.map(({node}, index) => {
              return(
                  <MinistryItem
                    classes="col-sm-4 col-6 ministry-item"
                    title={node.title}
                    url={node?.path?.alias}
                    body={node?.body?.summary}
                    image={node?.relationships?.field_image?.image_style_uri?.large}
                    key={index}
                    buttonType={node?.field_form?.drupal_internal__target_id && node?.field_form?.drupal_internal__target_id !== "prayer" ? "modal" : "url"}
                    formId={node?.field_form?.drupal_internal__target_id}
                    formData={node?.relationships?.field_form}
                  />
              )
              })
             }

            </div>

            </div>
          </div>
        </div>
    </div>
    </div>
    </section>
    {promoted && promoted?.field_show_on_here_for_you === "promoted"  &&
      <section>
        <div className="container p-0 p-b-80 page-care">
          <div className="center-section p-l-r-153 medium-p-l-r-16 small-p-l-r-16">
            <MinistryItem
              classes="row ministry-item promoted background-beig"
              title={promoted?.title}
              url={promoted?.path?.alias}
              body={promoted?.body?.summary}
              image={promoted?.relationships?.field_image?.image_style_uri?.large}
              columns={2}
              buttonType={promoted?.field_form?.drupal_internal__target_id ? "modal" : "url"}
              formData={promoted?.relationships?.field_form}
            />
          </div>
        </div>
      </section>
     }
    </>
  )
}


 export default CarePage
